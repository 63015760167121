<template>
    <DashboardCard>
        <template #title>
            {{ t('title') }}
        </template>
        <div :class="$style.content">
            <div :class="$style.middle">
                <img
                    src="/img/invite-friends.png"
                    alt="fennec friends"
                    :class="$style.friendsImg"
                />
                <div :class="$style.middleRight">
                    <div :class="['headerOne', $style.friends]" v-if="userStore.user?.usersInvited">
                        <span>{{ t('friends') }}</span>
                        <span>{{ userStore.user?.usersInvited }}</span>
                    </div>
                    <Button
                        :title="t('buttonTitle')"
                        size="m"
                        @click="openInviteModal"
                        :class="$style.button"
                    />
                </div>
            </div>
            <div :class="['textRegular',$style.description]">
                {{ t('description') }}
            </div>
        </div>
    </DashboardCard>
</template>

<script lang="ts" setup>
import { defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
import { useUserStore } from '@/store/user-store'
import DashboardCard from './DashboardCard.vue'

/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'openInviteModal'): void}>()

function openInviteModal() {
    emit('openInviteModal')
}

const { t } = useI18n()

const userStore = useUserStore()
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-large)
}

.description {
  color: var(--white-50);
}

.middle {
  display: flex;
  align-items: center;
  gap: var(--gap-medium);
}

.friendsImg {
    width: 64px;
}

.middleRight {
    flex-grow: 1;
}

.friends {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--gap-medium);
}

.button {
    width: 100%;
}
</style>

<i18n lang="json">
  {
      "en": {
          "title": "Invite friends and earn rewards",
          "description": "Get 10k coins for each friend you invite, plus 5% of their daily earnings",
          "buttonTitle": "Invite Friends",
          "friends": "Friends"
      }
  }
  </i18n>
