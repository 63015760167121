import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { Banner } from '@/modules/adv/banner'
import {
    AdProviderStatus, AdvService, AdvServiceConfig, PageAdOptions,
} from '../adv'

const GAM_SOURCE = '//www.googletagservices.com/tag/js/gpt.js'

export type AdUnitName = 'interstitial' | 'rewarded' | 'p2e_rewarded' | 'sticky_portrait'
const ADMANAGER_ACCOUNT = process.env.VUE_APP_ADMANAGER_ACCOUNT
export class GoogleAdsService implements AdvService {
    private readonly gpt: typeof googletag

    private readonly slots = new Map<googletag.Slot, Banner | RewardedBanner>()

    private formats: Record<AdUnitName, googletag.enums.OutOfPageFormat> | null = null

    readonly serviceStatus: Promise<AdProviderStatus>

    constructor({ targeting = {} }: AdvServiceConfig) {
        // @ts-ignore
        window.googletag = window.googletag || { cmd: [] }

        this.gpt = window.googletag

        this.serviceStatus = new Promise((res, rej) => {
            const script = document.createElement('script')
            script.src = GAM_SOURCE

            script.addEventListener('error', () => {
                rej(AdProviderStatus.offline)
            })
            document.body.appendChild(script)

            this.gpt.cmd.push(() => {
                this.formats = {
                    // @ts-ignore Google doesn't care about updating their types library
                    // interstitial: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    // temp rewarded usage rewarded ads as interstitials
                    interstitial: this.gpt.enums.OutOfPageFormat.REWARDED,
                    rewarded: this.gpt.enums.OutOfPageFormat.REWARDED,
                    p2e_rewarded: this.gpt.enums.OutOfPageFormat.REWARDED,
                    sticky_portrait: this.gpt.enums.OutOfPageFormat.BOTTOM_ANCHOR,
                }

                Object.entries(targeting).forEach(([key, value]) => {
                    this.gpt.pubads().setTargeting(key, value)
                })

                this.gpt.pubads().set('page_url', 'playgama.com')

                this.gpt.pubads().addEventListener('rewardedSlotReady', (event) => {
                    const banner = this.slots.get(event.slot) as RewardedBanner
                    if (!banner) return
                    banner.triggerReady(event.makeRewardedVisible)
                })
                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotReady', (event) => {
                    // @ts-ignore Google doesn't care about updating their types library
                    this.slots.get(event.slot)?.triggerReady(event.makeGameManualInterstitialVisible)
                })

                this.gpt.pubads().addEventListener('rewardedSlotClosed', (event) => {
                    this.slots.get(event.slot)?.triggerClosed()
                    this.gpt.destroySlots([event.slot])
                    this.slots.delete(event.slot)
                })

                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotClosed', (event) => {
                    this.slots.get(event.slot)?.triggerClosed()
                    this.gpt.destroySlots([event.slot])
                    this.slots.delete(event.slot)
                })

                this.gpt.pubads().addEventListener('rewardedSlotGranted', (event) => {
                    this.slots.get(event.slot)?.triggerRewarded(event.payload)
                })

                this.gpt.pubads().addEventListener('impressionViewable', (event) => {
                    this.slots.get(event.slot)?.triggerViewable()
                })

                this.gpt.pubads().addEventListener('slotRenderEnded', (event) => {
                    if (event.isEmpty) {
                        this.slots.get(event.slot)?.triggerEmpty()
                    } else {
                        this.slots.get(event.slot)?.triggerRendered()
                    }
                })

                res(AdProviderStatus.online)
            })
        })
    }

    // https://developers.google.com/publisher-tag/samples/display-rewarded-ad

    prepareOutOfPageAd(type: AdUnitName): Promise<RewardedBanner> {
        return new Promise((res, rej) => {
            let slot: googletag.Slot | null
            this.gpt.cmd.push(() => {
                slot = this.gpt.defineOutOfPageSlot(
                    `${ADMANAGER_ACCOUNT}/${type}`,
                    this.formats![type],
                )

                // Slot returns null if the page or device does not support rewarded ads.
                if (!slot) {
                    rej()
                    return
                }

                const banner = new RewardedBanner({})

                this.slots.set(slot, banner)

                res(banner)

                slot.addService(this.gpt.pubads())

                this.gpt.enableServices()
                this.gpt.display(slot)
            })
        })
    }

    requestPageAd(options: PageAdOptions): Promise<Banner> {
        return new Promise((resolve, reject) => {
            this.gpt.cmd.push(() => {
                let refreshInterval: ReturnType<typeof setInterval>
                let slot: googletag.Slot | null = null
                if (options.type === 'sticky_portrait') {
                    slot = this.gpt.defineOutOfPageSlot(
                        `${ADMANAGER_ACCOUNT}/sticky_portrait`,
                        this.formats!.sticky_portrait,
                    )
                } else if (options.sizes) {
                    slot = this.gpt.defineSlot(
                        `${ADMANAGER_ACCOUNT}/${options.type}`,
                        options.sizes,
                        options.el,
                    )
                }
                if (!slot) {
                    reject()
                    return
                }

                slot.addService(this.gpt.pubads())
                this.gpt.enableServices()
                this.gpt.pubads().setCentering(true)
                this.gpt.pubads().collapseEmptyDivs()
                this.gpt.display(slot)

                if (options.refresh) {
                    refreshInterval = setInterval(() => {
                        this.gpt.pubads().refresh([slot!])
                    }, options.refresh * 1000)
                }

                const banner = new Banner({
                    destroy: () => {
                        this.gpt.destroySlots([slot!])
                        if (refreshInterval) {
                            clearInterval(refreshInterval)
                        }
                        this.slots.delete(slot!)
                    },
                })

                this.slots.set(slot, banner)

                resolve(banner)
            })
        })
    }
}
