import Logo from './Logo.vue'
import Burger from './Burger.vue'
import Fullscreen from './Fullscreen.vue'
import ExitFullscreen from './ExitFullscreen.vue'
import ExclamationMark from './ExclamationMark.vue'
import Copy from './Copy.vue'
import Cross from './Cross.vue'
import CrossSlim from './CrossSlim.vue'
import Rotate from './Rotate.vue'
import Arrow from './Arrow.vue'
import TwoPlayer from './2_player.vue'
import Action from './Action.vue'
import AllGames from './AllGames.vue'
import Balloons from './Balloons.vue'
import Boys from './Boys.vue'
import Cards from './Cards.vue'
import Casino from './Casino.vue'
import Economic from './Economic.vue'
import Educational from './Educational.vue'
import Girls from './Girls.vue'
import Horrors from './Horrors.vue'
import Hypercasual from './Hypercasual.vue'
import Imitations from './Imitations.vue'
import Io from './Io.vue'
import Kids from './Kids.vue'
import Midcore from './Midcore.vue'
import New from './New.vue'
import Puzzle from './Puzzle.vue'
import Racing from './Racing.vue'
import Random from './Random.vue'
import Recommended from './Recommended.vue'
import Role from './Role.vue'
import Simulator from './Simulator.vue'
import Sports from './Sports.vue'
import Tabletop from './Tabletop.vue'
import Tests from './Tests.vue'
import Trending from './Trending.vue'
import Up from './Up.vue'
import Adventure from './Adventure.vue'
import Applications from './Applications.vue'
import Arcade from './Arcade.vue'
import Baby from './Baby.vue'
import Bejeweled from './Bejeweled.vue'
import Clicker from './Clicker.vue'
import Cooking from './Cooking.vue'
import Farming from './Farming.vue'
import InGamePurchase from './InGamePurchase.vue'
import Match3 from './Match3.vue'
import Multiplayer from './Multiplayer.vue'
import Popular from './Popular.vue'
import Quiz from './Quiz.vue'
import Shooting from './Shooting.vue'
import Soccer from './Soccer.vue'
import Social from './Social.vue'
import Stickman from './Stickman.vue'
import Strategy from './Strategy.vue'
import ThreeD from './ThreeD.vue'
import Pencil from './Pencil.vue'
import Telegram from './Telegram.vue'
import CheckMark from './CheckMark.vue'
import ArrowOut from './ArrowOut.vue'
import Add from './Add.vue'
import Ton from './Ton.vue'

export const iconsList = {
    logo: Logo,
    burger: Burger,
    fullscreen: Fullscreen,
    exitFullscreen: ExitFullscreen,
    exclamationMark: ExclamationMark,
    copy: Copy,
    cross: Cross,
    crossSlim: CrossSlim,
    rotate: Rotate,
    arrow: Arrow,
    '2_player': TwoPlayer,
    action: Action,
    all_games: AllGames,
    balloons: Balloons,
    boys: Boys,
    cards: Cards,
    casino: Casino,
    economic: Economic,
    educational: Educational,
    girls: Girls,
    horrors: Horrors,
    hypercasual: Hypercasual,
    imitations: Imitations,
    io: Io,
    kids: Kids,
    midcore: Midcore,
    new: New,
    puzzle: Puzzle,
    racing: Racing,
    random: Random,
    recommended: Recommended,
    role: Role,
    simulator: Simulator,
    sports: Sports,
    tabletop: Tabletop,
    tests: Tests,
    trending: Trending,
    up: Up,
    adventure: Adventure,
    applications: Applications,
    arcade: Arcade,
    baby: Baby,
    bejeweled: Bejeweled,
    clicker: Clicker,
    cooking: Cooking,
    farming: Farming,
    in_game_purchase: InGamePurchase,
    match3: Match3,
    multiplayer: Multiplayer,
    popular: Popular,
    quiz: Quiz,
    shooting: Shooting,
    soccer: Soccer,
    social: Social,
    stickman: Stickman,
    strategy: Strategy,
    '3d': ThreeD,
    pencil: Pencil,
    telegram: Telegram,
    checkMark: CheckMark,
    arrowOut: ArrowOut,
    ton: Ton,
    add: Add,
}

export type IconNameType = keyof typeof iconsList
