export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "invitedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " invites you to join Playgama"])},
        "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were invited to join Playgama"])},
        "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Playgama and Track Your Gaming Progress!"])},
        "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Join Playgama?"])},
        "infoItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn coins through gameplay and tasks"])},
        "infoItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem for rewards in the Playgama Shop"])},
        "infoItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase your playtime value"])},
        "infoItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of our unique play-to-earn system"])}
      }
    }
  })
}
