<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <main :class="$style.container">
                <h1 :class="$style.header">{{ pageTitle }}</h1>
                <p :class="['subheader', $style.subheader]">
                    {{ t('subheader') }}
                </p>
                <div :class="$style.loginButton">
                    <GoogleAuth />
                </div>
                <div :class="$style.info">
                    <div>
                        <div class="headerOne">{{ t("infoTitle") }}</div>
                        <ul :class="['textRegular', $style.infoList]">
                            <li>{{ t("infoItem1") }}</li>
                            <li>{{ t("infoItem2") }}</li>
                            <li>{{ t("infoItem3") }}</li>
                            <li>{{ t("infoItem4") }}</li>
                        </ul>
                    </div>
                    <img :class="$style.imgFennec" alt="invite" src="/img/fennec-back.png"/>
                </div>
            </main>
        </template>
        <template #rightSide>
            <RightSide
                :recentlyGames="recommendedGames"
            />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
    computed,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import RightSide from '@/components/RightSide.vue'
import GoogleAuth from '@/components/GoogleAuth.vue'
import { useInviteStore } from './invite-store'

const { t } = useI18n()
const $route = useRoute()

const categoriesStore = useCategoriesStore()
const inviteStore = useInviteStore()

const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
} = storeToRefs(categoriesStore)

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
    await inviteStore.getInviterName($route.params.inviteCode as string)
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (!contentCategoriesList.value.length) {
        await categoriesStore.setMainData()
    }
    if (!inviteStore.inviterName) {
        await inviteStore.getInviterName($route.params.inviteCode as string)
    }
    await inviteStore.setInviter($route.params.inviteCode as string)
})

const pageTitle = computed(() => {
    if (inviteStore.inviterName) {
        return t('invitedBy', { name: inviteStore.inviterName })
    }
    return t('invited')
})
</script>

<style module>
.container {
    padding: var(--gap-regular) var(--gap-xxlarge);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.subheader {
    color: var(--white-50);
    margin-top: var(--gap-small);
}

.loginButton {
    margin-top: 24px;
    margin-bottom: 48px;
    height: 44px;
}

.info {
    display: flex;
    align-items: center;
    padding: var(--gap-large);
    border: 1px solid var(--white-25);
    border-radius: var(--radius-large);
}

.infoList {
    margin-top: var(--gap-large);
    list-style-type: disc;
    color: var(--white-50);
    line-height: var(--line-height-big);
    list-style-position: inside;
    text-align: start;
}

.imgFennec {
    height: 160px;
    margin-left: 40px;
}

@media (--mobile) {
    .container {
        padding: var(--gap-regular);
    }

    .imgFennec {
        display: none;
    }

    .infoList {
        list-style-position: outside;
    }
}
</style>

<i18n lang="json">
{
  "en": {
      "invitedBy": "{name} invites you to join Playgama",
      "invited": "You were invited to join Playgama",
      "subheader": "Join Playgama and Track Your Gaming Progress!",
      "infoTitle": "Why Join Playgama?",
      "infoItem1": "Earn coins through gameplay and tasks",
      "infoItem2": "Redeem for rewards in the Playgama Shop",
      "infoItem3": "Increase your playtime value",
      "infoItem4": "Part of our unique play-to-earn system"
  }
}
</i18n>
