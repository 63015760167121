export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Friends to Playgama!"])},
        "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your unique link and earn Fennec Coins when friends join and play!"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Link"])},
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
        "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works:"])},
        "howItWorks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your link with friends"])},
        "howItWorks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends sign up and play"])},
        "howItWorks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You both earn Fennec Coins!"])}
      }
    }
  })
}
