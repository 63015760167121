export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends and earn rewards"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 10k coins for each friend you invite, plus 5% of their daily earnings"])},
        "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Friends"])},
        "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])}
      }
    }
  })
}
