<template>
    <Modal
        :isOpen="props.isOpen"
        :withCloseButton="true"
        @closeModal="closeInviteModal"
    >
        <div :class="$style.content">
            <img
                :class="$style.modalImg"
                src="/img/pizza.png"
                alt="fennec"
            >
            <div :class="$style.header">
                <div class="headerOne">{{ t('header') }}</div>
                <p :class="['subheader', $style.subheader]">
                    {{ t('subheader') }}
                </p>
            </div>
            <div :class="['headerOne', $style.link]">{{ inviteLinkAbsolute }}</div>
            <Button
                :title="modalButtonTitle"
                size="l"
                @click="copyInviteLinkToClipboard"
                width="full"
            />
            <div :class="['textRegular', $style.description]">
                <p>{{ t("howItWorks") }}</p>
                <ol :class="$style.descriptionList">
                    <li>{{ t("howItWorks1")}}</li>
                    <li>{{ t("howItWorks2")}}</li>
                    <li>{{ t("howItWorks3")}}</li>
                </ol>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import {
    computed, ref, defineProps, defineEmits,
} from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import { useUserStore } from '@/store/user-store'

type Props = {
  isOpen: boolean;
}
const props = defineProps<Props>()

/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'closeInviteModal'): void}>()

function closeInviteModal() {
    emit('closeInviteModal')
}

const { t } = useI18n()

const router = useRouter()
const userStore = useUserStore()

const modalButtonTitle = ref(t('copyLink'))

const inviteLinkAbsolute = computed(() => ((process.env.VUE_APP_HOST_URL || '') + router.resolve({ name: 'invite', params: { inviteCode: userStore.user?.referralCode } }).href))

function copyInviteLinkToClipboard() {
    navigator.clipboard.writeText(inviteLinkAbsolute.value)
    modalButtonTitle.value = t('copied')
    setTimeout(() => {
        modalButtonTitle.value = t('copyLink')
    }, 1500)
}
</script>

<style module>
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--gap-large);
  background: radial-gradient(circle at 50% 35%, var(--purple-16) 0 , transparent 45%, transparent);
}

.friendsImg {
  width: 80px;
  height: 80px;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--gap-small);
}

.subheader {
  color: var(--white-50);
  text-align: center;
}

.link {
  padding-bottom: var(--gap-xsmall);
  border-bottom: 1px solid var(--white-25);
  word-break: break-all;
  text-align: center;
}

.description {
  text-align: center;
  color: var(--white-50);
}

.descriptionList {
  text-align: center;
  list-style-position: inside;
  padding-left: 0;
  margin-top: var(--gap-xsmall);
}

</style>

<i18n lang="json">
  {
      "en": {
        "header": "Invite Friends to Playgama!",
        "subheader": "Share your unique link and earn Fennec Coins when friends join and play!",
        "copyLink": "Copy Link",
        "copied": "Copied!",
        "howItWorks": "How it works:",
        "howItWorks1": "Share your link with friends",
        "howItWorks2": "Friends sign up and play",
        "howItWorks3": "You both earn Fennec Coins!"
      }
  }
  </i18n>
