<template>
    <Modal @closeModal="handleClose" :isOpen="isOpen" :withCloseButton="true">
        <div class="title">{{ t('title') }}</div>
        <ContactForm :page="page" @submitted="handleClose" />
    </Modal>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import Modal from '@/components/Modal.vue'
import ContactForm from '@/components/ContactForm/index.vue'

type ReportProblemModalProps = {
    isOpen: boolean,
    page: string,
}

defineProps<ReportProblemModalProps>()
/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'closeReportModal'): void}>()

const { t } = useI18n()
function handleClose() {
    emit('closeReportModal')
}
</script>

<style module>
.modalContent {
    width: 100%;
    max-width: 728px;
}
</style>

<i18n lang="json">
{
    "en": {
        "title": "Report a problem",
        "close": "Close"
    }
}
</i18n>
