<template>
    <Teleport to="body">
        <Overlay :isOpen="props.isOpen">
            <ModalBase
                :isOpen="props.isOpen"
                :class="styles.content"
                @closeModal="closeModal"
            >
                <template #default>
                    <slot />
                    <Button
                        v-if="withCloseButton"
                        text
                        :title="t('close')"
                        size="s"
                        :class="$style.closeBtn"
                        @click="closeModal"
                    />
                </template>
            </ModalBase>
        </Overlay>
    </Teleport>
</template>

<script setup lang="ts">
import {
    useCssModule,
    defineEmits,
    defineProps,
} from 'vue'
import { useI18n } from 'vue-i18n'
import Overlay from '@/components/Overlay.vue'
import ModalBase from '@/components/ModalBase.vue'
import Button from '@/components/Button.vue'

type ModalProps = {
    isOpen: boolean
    withCloseButton?: boolean
}

const props = defineProps<ModalProps>()
const styles = useCssModule()
/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'closeModal'): void}>()

const { t } = useI18n()
function closeModal() {
    emit('closeModal')
}
</script>

<style module>
.content {
    position: relative;
    border-radius: var(--radius-regular);
    padding: var(--gap-regular);
    color: var(--white);
    background-color: var(--background);
    max-width: 420px;
    width: 100%;
    box-sizing: border-box;
}

.closeBtn {
    composes: accent from global;
    margin-top: var(--gap-regular);
    width: 100%;
}

@media (--tablet) {
    .content {
        max-width: 70%;
    }
}

/* поскольку мобилка зависит еще от ориентации,
 * то может быть ситуация, когда по ширине экран больше планшета,
 * но ориентирован горизонтально и задается мобильная верстка.
 * поэтому явно задаем еще правило для телефонов
*/
@media (--mobile) {
    .content {
        max-width: 100%;
    }
}
</style>

<i18n lang="json">
    {
        "en": {
            "close": "Close"
        }
    }
    </i18n>
