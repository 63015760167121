<template>
    <!-- eslint-disable max-len -->
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.833H3.333V17.5h10v-1.667H5v-10ZM6.667 2.5v11.667h10V2.5h-10ZM15 12.5H8.333V4.167H15V12.5Z" fill="#fff"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Copy',
})
</script>
