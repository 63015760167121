import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import { notAuthGuard } from './guards'

const routes = [
    {
        path: '/',
        component: () => import('@/InitWrapper.vue'),
        children: [
            {
                path: '',
                name: 'main',
                component: () => import('@/pages/Homepage/index.vue'),
            },
            {
                path: 'game/:game',
                name: 'game-page',
                component: () => import('@/pages/Games/Game.vue'),
            },
            {
                path: 'export/game/:game',
                name: 'export-game-page',
                component: () => import('@/pages/Games/ExportGame.vue'),
            },
            {
                path: 'category/:category',
                name: 'category',
                component: () => import('@/pages/Category/CategoryPage.vue'),
            },
            {
                path: 'publishers',
                name: 'publishers',
                component: () => import('@/pages/old-landings/publishers/Publishers.vue'),
            },
            {
                path: 'advertisers',
                name: 'advertisers',
                component: () => import('@/pages/old-landings/advertisers/Advertisers.vue'),
            },
            {
                path: 'confidential',
                name: 'confidential',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'termsofuse',
                name: 'termsofuse',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'license',
                name: 'license',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'partners-api',
                name: 'partners-api',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: () => import('@/pages/ContactUsPage.vue'),
            },
            {
                path: 'profile',
                name: 'userProfile',
                component: () => import('@/pages/UserProfile/UserProfile.vue'),
            },
            {
                path: 'invite/:inviteCode',
                name: 'invite',
                beforeEnter: notAuthGuard,
                component: () => import('@/pages/Invite/Invite.vue'),
            },
            {
                path: '/:catchAll(.*)',
                component: () => import('@/pages/NotFoundPageWrapped.vue'),
            },
        ],
    },
]

export default function getRouter(isServer: boolean) {
    return createRouter({
        routes,
        history: isServer ? createMemoryHistory() : createWebHistory(),
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                }
            }

            if (savedPosition) {
                return savedPosition
            }

            return { top: 0 }
        },
    })
}
