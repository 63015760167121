<template>
    <DashboardCard>
        <ul :class="$style.tasks">
            <li v-for="tag in sortedTags" :key="tag">
                <DashboardTitle>{{ t(tag) }}</DashboardTitle>
                <UserTask
                    v-for="task in tasksByTags[tag as TasksTagType]"
                    :key="task._id"
                    :task="task"
                    @openClaim="openClaimModal(task)"
                />
            </li>
        </ul>
    </DashboardCard>
    <DashboardCard v-if="tasksByTags['friends']">
        <div :class="$style.inviteBanner">
            <div :class="$style.inviteBannerContent">
                <div :class="['headerOne', $style.inviteBannerTitle]">
                    {{ t('friendsBannerTitle') }}
                </div>
                <div :class="['textRegular', $style.inviteBannerDescription]">
                    {{ t('friendsBannerDescription') }}
                </div>
                <Button
                    :title="t('friendsBannerButton')"
                    size="m"
                    @click="openInviteModal"
                    :class="$style.inviteButton"
                />
                <ul :class="['textRegular', $style.inviteBenefits]">
                    <li>{{ t('inviteBenefit1') }}</li>
                    <li>{{ t('inviteBenefit2') }}</li>
                    <li>{{ t('inviteBenefit3') }}</li>
                </ul>
            </div>
            <img
                src="/img/invite-friends.png"
                alt="fennec friends"
                :class="$style.friendsImg"
            />
        </div>
        <DashboardTitle>
            <div :class="$style.friendsTitle">
                <span>{{ t('friends') }}</span>
                <span>{{ props.usersInvited || '' }}</span>
            </div>
        </DashboardTitle>
        <UserTask
            v-for="task in tasksByTags['friends']"
            :key="task._id"
            :task="task"
            @openClaim="openClaimModal(task)"
        />
    </DashboardCard>
</template>
<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import { TasksTagType, TaskTagMap, type Task } from '@/types'
import UserTask from '@/components/UserCabinet/components/UserTask.vue'
import Button from '@/components/Button.vue'
import DashboardCard from './DashboardCard.vue'
import DashboardTitle from './DashboardTitle.vue'

type UserTasksProps = {
    tasks: Task[]
    usersInvited?: number;
}

const props = defineProps<UserTasksProps>()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'openClaimModal', task: Task): void
    (e: 'openInviteModal'): void
}>()

const { t } = useI18n()

function openClaimModal(task: Task) {
    emit('openClaimModal', task)
}

function openInviteModal() {
    emit('openInviteModal')
}

// "friends" tag has separate view
const TASKS_TAG_SORT: TasksTagType[] = ['daily', 'playGames', 'ads', 'register']

const tasksByTags = computed(() => {
    const map: Partial<Record<TasksTagType, Task[]>> = {}
    props.tasks.forEach((task) => {
        const tag = TaskTagMap[task.type]
        if (!tag) {
            return
        }
        if (map[tag]) {
            map[tag].push(task)
        } else {
            map[tag] = [task]
        }
    })
    return map
})

const sortedTags = computed(() => (Object.keys(tasksByTags.value) as TasksTagType[])
    .sort((a, b) => TASKS_TAG_SORT.indexOf(a) - TASKS_TAG_SORT.indexOf(b)).filter((t) => t !== 'friends'))
</script>

<style module>

.tasks {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xlarge);
}

.inviteBanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--gap-large);
}

.inviteBannerTitle {
    margin-bottom: var(--gap-medium);
}

.inviteButton {
    width: 240px;
    margin: var(--gap-large) 0;
}

.inviteBenefits {
    color: var(--white-50);
    list-style: disc;
    list-style-position: inside;
}

.friendsImg {
    width: 180px;
}

.friendsTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (--mobile) {
    .friendsImg {
        display: none;
    }
}
</style>

<i18n lang="json">
{
    "en": {
        "daily": "Daily",
        "register": "Register",
        "playGames": "Play Games",
        "ads": "Ads",
        "friends": "Friends",
        "friendsBannerTitle": "Invite Friends & Get Coins",
        "friendsBannerDescription": "Share your link, grow your pack!",
        "friendsBannerButton": "Invite friends",
        "inviteBenefit1": "Earn 100 coins for each friend",
        "inviteBenefit2": "Get 5% of their earnings",
        "inviteBenefit3": "Unlock coin multipliers and rewards"
    }
}
</i18n>
