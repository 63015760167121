import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useUserStore } from '@/store/user-store'

export function notAuthGuard(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const userStore = useUserStore()
    userStore.getUser().then((user) => {
        if (user) {
            next('/')
        }
        next()
    })
}
